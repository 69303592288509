<template>
    <BaseLayout head-title="Головна | КапКульт" main-margin-top="0">
        <div class="h-[90vh] pt-20">
            <div
                class="h-full bg-[url('@/../images/home/hero.png')] bg-auto lg:bg-cover transition-all duration-[2000ms]"
                :class="css.bgPosition"
            >
                <div class="h-full">
                    <div class="flex pt-5 ml-5 space-x-3">
                        <img v-for="image in beans"
                             :class="['w-10 h-full md:w-14 transition-all duration-500', `delay-${image.delay}`, css.beanVisibility]"
                             :src="image.src" alt="">
                    </div>
                    <div class="h-full pb-12 flex items-center transition-all duration-[2000ms]"
                         :class="css.textMargin">
                        <div class="container px-4 m-auto">
                            <div
                                class="max-w-sm md:max-w-3xl m-auto text-center font-heading text-white drop-shadow-heading [word-spacing:5px] lg:[word-spacing:30px]">
                                <h1 class="leading-[3.5rem] md:leading-[3.5rem] xl:leading-[4rem] text-3xl md:text-4xl xl:text-5xl">
                                    СПЕШЕЛТІ КАВА<br>В ОДНОМУ МІСЦІ
                                </h1>
                                <div class="flex justify-center mt-10 text-2xl md:text-3xl xl:text-4xl">
                                    <div>
                                        <Link
                                            class="flex items-center mb-3"
                                            :class="{'text-green-500': activeItem === 'coffee'}"
                                            :href="route('categories.show', {category: 'coffee'})"
                                            @mouseenter="activeItem = 'coffee'"
                                        >
                                            <svg class="h-9 xl:h-11 mr-5" viewBox="0 0 23 45"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path :class="{'fill-green-500': activeItem === 'coffee'}"
                                                      d="M5.58257 44.6605H0V0H5.58257V5.58257H11.1651V13.9564H16.7477V19.539H22.3303V25.1216H16.7477V30.7041H11.1651V39.078H5.58257V44.6605Z"
                                                      fill="white"/>
                                            </svg>
                                            ЗЕРНО
                                        </Link>
                                        <Link
                                            class="flex items-center"
                                            :class="{'text-green-500': activeItem === 'drips'}"
                                            :href="route('categories.show', {category: 'drips'})"
                                            @mouseenter="activeItem = 'drips'"
                                        >
                                            <svg class="h-9 xl:h-11 mr-5" viewBox="0 0 23 45"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path :class="{'fill-green-500': activeItem === 'drips'}"
                                                      d="M5.58257 44.6605H0V0H5.58257V5.58257H11.1651V13.9564H16.7477V19.539H22.3303V25.1216H16.7477V30.7041H11.1651V39.078H5.58257V44.6605Z"
                                                      fill="white"/>
                                            </svg>
                                            ДРІПИ
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative top-0 bg-white" id="products">
            <section class="mb-5 md:mb-16">
                <Container>
                    <div class="h-[10vh] min-h-[4rem] max-h-28 flex justify-between items-center">
                        <div class="flex overflow-auto -mb-5 space-x-4 pt-1">
                            <span class="md:text-xl uppercase font-heading mt-1">Зерно</span>
                            <SmallButton
                                v-for="(filter, index) in productFilters"
                                :text="filter.title"
                                :color="activeFilter === index ? 'black' : 'white'"
                                :text-color="activeFilter === index ? 'white' : 'black'"
                                :border="activeFilter === index ? 'white' : 'black'"
                                :loading="this.productForm.processing"
                                class="cursor-pointer mb-5 min-w-24 md:min-w-32 lowercase"
                                @click="selectFilter(index)"
                            />
                        </div>

                        <Link :href="activeProductsUrl" class="hover:text-indigo-600 items-center hidden lg:flex font-bold">
                            Дивитись усі
                            <Chevron class="ml-3 h-3"/>
                        </Link>
                    </div>

                    <div class="overflow-x-scroll scrollbar">
                        <div class="flex flex-nowrap">
                            <div
                                v-for="product in products"
                                class="flex-none mb-3"
                            >
                                <div class="w-40 mr-5 shadow">
                                    <Link :href="route('products.show', {product})">
                                        <div
                                            class="flex justify-center h-40 items-center border border-b-0 border-black">
                                            <ResponsiveImage
                                                class="h-36 w-36 object-cover object-center"
                                                :alt="product.title"
                                                :media-item="product.image"
                                            />
                                        </div>

                                        <div class="p-4 bg-black border border-l-black border-t-0">
                                            <p class="line-clamp-2 text-white text-sm mb-3 h-10">{{ product.title }}</p>
                                            <span class="text-green-500 mt-1 text-xs font-heading">
                                                {{ product.price.formatted }}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center lg:hidden mt-5">
                        <Link :href="activeProductsUrl" class="w-56">
                            <PrimaryButton color="white" text-color="black" hover-color="green">
                                Дивитись усі
                            </PrimaryButton>
                        </Link>
                    </div>
                </Container>
            </section>

            <section class="bg-gray-100 text-sm">
                <Container :no-padding="true">
                    <div class="block md:flex items-center">
                        <div class="h-64 md:h-full w-full">
                            <img class="bg-white h-full w-full md:w-auto object-cover object-right"
                                 src="@/../images/home/about1.png" alt="">
                        </div>
                        <div class="p-5 md:p-10">
                            <p class="uppercase font-heading text-xl mb-5">Про нас:</p>
                            <p>Ми об'єднуємо найкращих обсмажників та спрощуємо вибір кави.</p>
                            <p>Віримо, що кожен любитель кави має право на доступ до найкращих сортів, тому зібрали їх на нашому маркетплейсі.</p>
                        </div>
                    </div>
                    <div class="block md:flex items-center">
                        <img class="md:max-w-sm xl:max-w-xl w-full h-64 object-cover"
                             src="@/../images/home/about2.png" alt="">
                        <div class="p-5 md:p-10 order-first">
                            <p>Ми гарантуємо свіжість кожного зерна, яке ви придбаєте на нашому маркетплейсі, безпечність вашого платежу та конфіденційність інформації. Також ми завжди готові допомогти вам з будь-якими питаннями або розв'язати проблему.</p>
                        </div>
                    </div>
                    <div class="block md:flex items-center">
                        <img class="md:max-w-sm xl:max-w-xl w-full h-64 object-cover"
                             src="@/../images/home/about3.png" alt="">
                        <div class="p-5 md:p-10 xl:px-24">
                            <p>Ми мріємо обʼєднати ентузіастів кавової спільноти в одне комьюніті. Простір необмежених можливостей для дослідження кави. Неважливо чи ти чемпіон змагань, бариста чи просто любиш заварювати каву вдома — тут собі місце знайде кожен.</p>
                        </div>
                    </div>
                </Container>
            </section>

            <section class="py-8 md:py-16">
                <Container>
                    <h2 class="text-xl font-heading uppercase mb-14 text-center">вже з нами</h2>

                    <swiper
                        :loop="true"
                        :lazy="true"
                        :spaceBetween="30"
                        :centeredSlides="true"
                        :autoplay="autoplay"
                        :breakpoints="breakpoints"
                        :modules="modules"
                    >
                        <swiper-slide
                            v-for="company in $page.props.brands"
                            class="px-4 mb-8"
                        >
                            <div class="flex items-center justify-center h-20 mb-5">
                                <Link :href="route('companies.products', {company})" aria-label="Товари бренду">
                                    <img
                                        class="object-contain transition-all duration-300 h-14 hover:h-20"
                                        :src="company.logo"
                                        loading="lazy"
                                        alt=""
                                    >
                                    <div
                                        class="swiper-lazy-preloader swiper-lazy-preloader-black"
                                    ></div>
                                </Link>
                            </div>
                        </swiper-slide>
                    </swiper>
                </Container>
            </section>

            <div class="h-10 bg-divider bg-contain"/>

            <section class="pt-8 md:pt-16">
                <Container :no-padding="true">
                    <h2 class="text-xl font-heading uppercase mb-8 md:mb-14 text-center">яке обрати зерно?</h2>

                    <div class="flex flex-wrap">
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{
                                country: 'braziliia,indiia,gonduras,meksika,peru',
                                processing: 'naturalna,musonna,dekaf'
                            }"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">з гірчинкою</p>
                                    <p class="text-sm">Класика міцного смаку кави.</p>
                                </div>
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose1.webp" alt=""/>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{
                                country: 'keniia,kolumbiia,burundi,kosta-rika',
                                processing: 'anaerobna,mita,naturalna-laktik'
                            }"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose2.png" alt=""/>
                                </div>
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">з кислинкою</p>
                                    <p class="text-sm">Подобаються яскраві смаки?</p>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{country: 'efiopiia,ruanda,salvador', 'grade-from': '85'}"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">збалансована</p>
                                    <p class="text-sm">Якщо можливо знайти баланс, він точно тут.</p>
                                </div>
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose3.png" alt=""/>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{'grade-from': '86'}"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose4.png" alt=""/>
                                </div>
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">щось цікаве</p>
                                    <p class="text-sm">Дещо більше ніж просто кава.</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </Container>
            </section>

            <div class="h-10 bg-divider bg-contain"/>

            <section class="bg-gray-100">
                <Container :no-padding="true">
                    <div class="md:flex md:h-96">
                        <div class="h-full flex md:w-1/2 items-center px-4 py-10">
                            <div>
                                <p class="text-xl font-heading uppercase mb-5">Мапа спешелті закладів</p>
                                <p>Хочеш швидко знаходити заклади поруч? Користуйся нашою мапою закладів.</p>
                            </div>
                        </div>
                        <div
                            class="h-60 md:h-full flex items-center justify-center md:w-1/2 bg-[url('@/../images/home/map.webp')] bg-cover">
                            <Link :href="route('map')" class="w-40">
                                <PrimaryButton class="max-w-40">
                                    <Map class="inline h-5"/>
                                    Мапа
                                </PrimaryButton>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import 'swiper/css';

import {Link, useForm} from '@inertiajs/vue3';
import BaseLayout from "@/Layouts/BaseLayout.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import ResponsiveImage from "@/Components/ResponsiveImage.vue";
import Badge from "@/Components/Badge.vue";
import Container from "@/Components/Base/Container.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import SmallButton from "@/Components/Buttons/SmallButton.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from "swiper/modules";
import Map from "@/Components/Svg/Map.vue";
import beanActive from "@/../images/home/bean-active.png"
import beanLost from "@/../images/home/bean-lost.png"

export default {
    components: {
        Map,
        SmallButton,
        Chevron,
        Container,
        Badge,
        BaseLayout,
        Link,
        PrimaryButton,
        ResponsiveImage,
        Swiper,
        SwiperSlide
    },
    props: {
        brands: Array,
        products: Array,
        filters: Array,
    },
    data() {
        const filters = []

        this.filters.forEach(parameter => parameter.options.forEach(option => {
            const parameterQuery = {}
            parameterQuery[parameter.slug] = option.slug

            filters.push({
                title: option.title,
                parameterQuery,
                url: this.route('categories.show', {category: 'coffee', ...parameterQuery})
            })
        }))

        const activeProductsUrl = this.route('categories.show', {category: 'coffee'})

        return {
            css: {
                bgPosition: 'bg-left-top',
                beanVisibility: 'opacity-0',
                textMargin: 'translate-y-full'
            },
            activeItem: 'coffee',
            brandInterval: null,
            activeFilter: 0,
            coffeeCategoryUrl: activeProductsUrl,
            activeProductsUrl,
            productFilters: [
                {
                    title: 'Новинки',
                    url: activeProductsUrl
                },
                ...filters
            ],
            productForm: useForm({}),
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                '640': {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 6,
                    spaceBetween: 50,
                },
            },
            modules: [Autoplay],
            autoplay: {
                delay: 2500,
                pauseOnMouseEnter: true
            },
            beans: [
                { src: beanActive, delay: "1000" },
                { src: beanActive, delay: "1200" },
                { src: beanActive, delay: "1400" },
                { src: beanLost, delay: "1600" },
                { src: beanLost, delay: "1800" }
            ]
        }
    },
    methods: {
        selectFilter(index) {
            window.scrollTo({
                top: document.getElementById('products').offsetTop - 80,
                behavior: "smooth",
            });

            this.activeFilter = index
            this.activeProductsUrl = this.productFilters[index].url

            this.productForm.get(this.route('home', this.productFilters[index].parameterQuery), {
                only: ['products'],
                replace: true,
                preserveState: true,
                preserveScroll: true,
            })
        },
        addJsonSchema(text) {
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = text;
            document.head.appendChild(script);
        }
    },
    mounted() {
        setTimeout(() => this.css = {
            bgPosition: 'bg-left-bottom',
            beanVisibility: 'opacity-100',
            textMargin: 'translate-y-0'
        })

        this.addJsonSchema(`{
          "@context" : "https://schema.org",
          "@type" : "WebSite",
          "url" : "https://cupcult.co",
          "name": "CupCult",
          "alternateName": "КапКульт",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://cupcult.co/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        }`)

        this.addJsonSchema(`{
          "@context": "https://schema.org",
          "@type": "OnlineStore",
          "url": "https://cupcult.co",
          "sameAs": ["https://www.instagram.com/cup.cult/", "https://t.me/cupcult_helpbot?start=start"],
          "logo": "https://cupcult.co/images/logo.jpg",
          "name": "CupCult",
          "alternateName": "КапКульт",
          "description": "Онлайн маркетплейс кави пропонує зерно, дріпи, обладнання для приготування смачної кави.",
          "email": "cupcult.official@gmail.com",
          "location": "Київ",
          "numberOfEmployees": {
              "@type": "QuantitativeValue",
              "minValue": 1,
              "maxValue": 10
          },
          "contactPoint": {
            "contactType": "Customer Service",
            "email": "cupcult.official@gmail.com"
          },
          "foundingDate": "2024-03-25",
          "founder": {
            "@type": "Person",
            "givenName": "Микола",
            "familyName": "Сірик"
          }
        }`)
    }
}
</script>

<style scoped>
.swiper-slide-active {
    @apply border-b-8 border-indigo-500
}
.swiper-slide-active img {
    @apply h-16
}
</style>
